body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #020411;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: 0.3s;
  outline: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
section {
  min-height: 100vh;
  overflow: hidden;
}
@media (max-width: 426px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 427px) and (max-width: 768px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1201px) and (max-width: 1600px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1601px) {
  html {
    font-size: 21px;
  }
}
