/* Basic Navbar styles */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0 2rem;
  height: 5rem;
  position: fixed;
  width: 100%;
  background: #010625;
  color: white;
  font-weight: bold;
  filter: drop-shadow(1px 9px 14px black);
  z-index: 9999;
}

.navLogo {
  height: 5rem;
  filter: hue-rotate(45deg);
}

/* Desktop Menu */
ul.desktop_Nav {
  list-style: none;
  display: flex;
  gap: 2rem;
}

ul.desktop_Nav li {
  cursor: pointer;
}

ul.desktop_Nav li:hover {
  color: #eee;
  transform: scale(1.1);
}

.mobile_Nav {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 60%;
  background-color: #010625;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  transition: right 0.4s ease-in-out;
  z-index: 10000;
}

.mobile_Nav li {
  padding: 1rem 0;
  cursor: pointer;
  color: white;
}

.mobile_Nav li:hover {
  color: #eee;
}

.mobile_Nav.open {
  right: 0;
  list-style: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.burger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 0.4rem;
  z-index: 10001;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.4s ease;
}

.burger.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.burger.toggle .line2 {
  opacity: 0;
}

.burger.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

  @media (max-width: 768px) {
  #des {
    display: none;
  }

  .burger {
    display: flex;
  }
}
